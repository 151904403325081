@font-face {
    font-family: 'dingding';
    src: url("./assets/fonts/DingTalk_JinBuTi.ttf");
}
html{
  height:100%;
}
body{
  margin: 0;
  padding:0;
  height:100%;
  display: flex;
  user-select: none;
  background: #f9f9f9;

  #root{
    height:100%;
    flex:1;
  }
}
body, input, textarea, select{
  font-family: "dingding", system-ui,-apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,sans-serif,
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  color:#111;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a:link,
a:active,
a:visited {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: inherit;
}

img{
  border:0;
}

.point-icon-white{
  width:16px;
  height:16px;
  background: url(./assets/images/point_white.png) no-repeat center;
  background-size: contain;
}
.point-icon-color{
  width:48px;
  height:60px;
  background: url(./assets/images/point_color.png) no-repeat center;
  background-size: contain;
}
