html {
    background: #f9f9f9;
}

.about-wrapper {
    height: 100%;

    .about-content {
        width: 1024px;
        box-sizing: border-box;
        margin: 24px auto ;
        padding: 120px 24px 36px;
        border-radius: 6px;
        background:#fff;
        // background: #fff url(../../assets/images/about_bg.png) no-repeat right top;
        // background-size: 640px auto;
        box-shadow: 0px 10px 20px 0px rgba(98, 117, 255, 0.20);

        h1 {
            margin: 0;
            padding: 0;
            font-size: 36px;
            color: #333;
            font-weight: normal;
        }

        h3 {
            display: flex;
            padding: 0;
            margin-top: 30px;
        }

        h3 span,
        h6 span {
            margin: 0;
            padding: 0;
            font-size: 24px;
            font-weight: normal;
            background-image: linear-gradient(270deg, #FF66BF 11.26%, #6175FF 88.74%);
            color: #555;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        h6{
            margin-top:38px;
        }

        .about-detail {
            margin: 30px 0 0;
            display: flex;
            flex-direction: column;

            section {
                margin-top: 18px;
                width: 976px;
                height: 300px;
                padding:0 56px 0 70px;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                border-radius: 6px;
                border: 1px solid rgba(17, 17, 17, 0.05);
                background: #F9F9F9;

                .text {
                    margin-left:75px;
                    color: #111;
                    font-family: "Dingding";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    opacity: .6;
                }

                .img {
                    flex-shrink: 0;
                    width:385px;
                    height:214px;
                    background-position: left center;
                    background-repeat: no-repeat;
                }
            }

            section:nth-child(1) {
                .img {
                    background-image: url(../../assets/images/about_a.png);
                    background-size: 385px 214px;
                }

            }

            section:nth-child(2) {
                padding:0 70px 0 56px;
                .img {
                    background-image: url(../../assets/images/about_b.png);
                    background-size: 385px 214px;
                }
            }


            section:nth-child(3) {
                .img {
                    background-image: url(../../assets/images/about_c.png);
                    background-size: 385px 214px;
                }

            }
        }
    }
}