.alert-panel-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17, 17, 17, .2);
    backdrop-filter: blur(10px);
    opacity: 0;
    transform: translateZ(0);
    transition: opacity .3s;

    .alert-panel {
        width: 420px;
        height: 302px;
        position: fixed;
        z-index: 100;
        left: 50%;
        top: 50%;
        border-radius: 6px;
        transform: translate(-50%, -50%) scale(.8);
        background: #fff;
        transition: transform .3s cubic-bezier(0, 1, 1, 1);

        .ap-header{
            height:120px;
            position: relative;
            .aph-bg{
                width:100%;
                height:170px;
                position:absolute;
                left:0;
                bottom:0;
                background-position: center bottom;
                background-repeat: no-repeat;
                background-image:url(../../assets/images/alert_bg.png);
                background-size:contain;
            }
            .ap-close{
                width:36px;
                height:36px;
                position: absolute;
                right:20px;
                top:19px;
                background:url(../../assets/images/close_icon.png) no-repeat center;
                background-size: contain;
                transform: rotate(0);
                transition: transform .3s;
                cursor: pointer;
            }
            .ap-close:hover {
                transform: rotate(180deg);
            }
        }
        .ap-content{
            margin-top:24px;
            h6{
                margin:0;
                padding:0;
                font-size: 18px;
                color:#111;
                text-align: center;
                font-weight: normal;
            }
            div{
                width:360px;
                margin:12px auto 0;
                min-height:46px;
                display: flex;
                justify-content: center;
                color:#a6a6a6;
                font-size: 14px;
            }
        }
        .ap-button{
            width:200px;
            height:36px;
            line-height: 36px;
            margin: 0 auto;
            font-size: 18px;
            text-align: center;
            color:#fff;
            border-radius: 6px;
            background: linear-gradient(90deg, #6175FF, #FF66BF);
            cursor:pointer;
        }
    }
}
.alert-panel-bg-v{
    opacity: 1;
    .alert-panel{
        transform: translate(-50%, -50%) scale(1);
    }
}