.login-panel-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 200;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17, 17, 17, .2);
    backdrop-filter: blur(10px);
    opacity: 0;
    transform: translateZ(0);
    transition: opacity .3s;

    .login-panel {
        width: 460px;
        height: 600px;
        position: fixed;
        z-index: 100;
        left: 50%;
        top: 50%;
        border-radius: 6px;
        transform: translate(-50%, -50%);
        background: #fff;

        .login-panel-close {
            width: 36px;
            height: 36px;
            position: absolute;
            right: 20px;
            top: 20px;
            background: url(../../assets/images/close_icon.png) no-repeat center;
            background-size: contain;
            transform: rotate(0);
            transition: transform .3s;
            cursor: pointer;
        }

        .login-panel-close:hover {
            transform: rotate(180deg);
        }

        .lp-header {
            width: 100%;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url(../../assets/images/login_bg.png) no-repeat center top;
            background-size: contain;

            span {
                box-sizing: border-box;
                width: 192px;
                height: 60px;
                font-size: 18px;
                text-align: center;
                padding-top: 45px;
                background: url(../../assets/images/logo.png) no-repeat center top;
                background-size: contain
            }
        }

        .lb-form {
            width: 412px;
            margin: 12px auto 0;

            .mobile-id input,
            .sms-code input,
            .invitation-code input {
                width: 406px;
                height: 56px;
                font-size: 18px;
                line-height: 60px;
                text-indent: 24px;
                border-radius: 6px;
                border: 1px solid rgba(17, 17, 17, 0.05);
                background: #F9F9F9;
                outline: none;
            }

            .mobile-id input:valid,
            .sms-code input:valid {
                border-color: rgba(0, 200, 0, .8);
                // background-color: #F9F9F9;
            }

            .sms-code {
                margin-top: 12px;
                position: relative;
                user-select: none;

                span {
                    width: 100px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    color: #999;
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    border-radius: 6px;
                    background: #FFF;
                    border: 1px solid #eeee;
                    cursor: pointer;
                }

                span.disabled {
                    background: #f9f9f9;
                    color: #bbb;
                    cursor: not-allowed;
                }
            }
            .invitation-code{
                height:0;
                opacity:0;
                overflow: hidden;
                transform: translateZ(0);
                transition:all .3s;
            }
            .invitation-code-v{
                margin-top:12px;
                height:60px;
                opacity:1;
            }

            .err-info {
                margin: 0;
                height: 0;
                color: #FF9191;
                overflow: hidden;
                transform: translateZ(0);
                transition: all .3s;
                display: flex;
                flex-direction: column;
            }

            .err-info-v {
                margin: 6px 0 0;
            }

            .agreement {
                margin-top: 8px;
                display: flex;
                align-items: center;
                color: #888;
                user-select: none;

                label,
                input {
                    cursor: pointer
                }

                a {
                    color: #888;
                }
            }
        }

        .handler {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            position: absolute;
            left: 0;
            bottom: 36px;

        }

        .lb-form input::placeholder {
            font-size: 18px;
            color: #aaa
        }
    }

    .login-sec {
        width: 460px;
        height: 600px;
        position: fixed;
        z-index: 100;
        left: 50%;
        top: 50%;
        border-radius: 6px;
        transform: translate(-50%, -50%);
        background: #fff;

        .ls-header {
            width: 100%;
            height: 200px;
            position: relative;
            border-radius: 6px 6px 0 0;
            background: url(../../assets/images/login_sec_bg.png) no-repeat center -1px;
            background-size: 102%;

            .avatar {
                width: 120px;
                height: 120px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -60px;
                border-radius: 100%;
                transition: filter .3s;
                cursor: pointer;

                img {
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                }

                .img-btn {
                    display: block;
                    width: 120px;
                    height: 120px;
                    background: url(../../assets/images/login_sec_avatar.png) no-repeat center;
                    background-size: contain;
                }

                .img-upload-percent {
                    width: 120px;
                    height: 120px;
                    line-height: 120px;
                    text-align: center;
                    color: #fff;
                    font-size: 18px;
                    border-radius: 50%;
                    position: absolute;
                    z-index: 10;
                    left: 0;
                    top: 0;
                    pointer-events: none;
                    opacity: 0;
                    transform: translateZ(0);
                    transition: opacity .3s;
                    background: rgba(0, 0, 0, .5);
                }

                .img-upload-percent-v {
                    pointer-events: all;
                    opacity: 1;
                }

                input[type="file"] {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    font-size: 120px;
                    line-height: 120px;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    opacity: 0;
                    appearance: none;
                }
            }

            .avatar:hover {
                filter: brightness(.9);
            }
        }

        .ls-form {
            width: 412px;
            margin: 95px auto 0;

            .user-name {
                position: relative;

                input {
                    width: 406px;
                    height: 56px;
                    font-size: 18px;
                    line-height: 60px;
                    text-indent: 24px;
                    border-radius: 6px;
                    border: 1px solid rgba(17, 17, 17, 0.05);
                    background: #F9F9F9;
                    outline: none;
                }

                input::placeholder {
                    color: #ccc;
                }

                input:valid {
                    border-color: rgba(0, 200, 0, .8);
                }

                span {
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    color: #666;
                    position: absolute;
                    right: 24px;
                    top: 12px;
                }
            }

            .err-info {
                line-height: 24px;
                margin: 0;
                color: #FF9191;
                height: 0;
                overflow: hidden;
                opacity: 0;
                transform: translateZ(0);
                transition: all .3s;
            }

            .err-info-v {
                opacity: 1;
                height: 24px;
                margin: 6px 0 10px;
            }
        }

        .handler {
            width: 100%;
            display: flex;
            justify-content: center;
            position: absolute;
            left: 0;
            bottom: 36px;

        }

        .lb-form input::placeholder {
            font-size: 18px;
            color: #aaa
        }
    }
}

.login-panel-wrapper-v {
    opacity: 1;
}
.back-login{
    position: absolute;
    left: 20px;
    top: 30px;
    cursor: pointer;
}
.register{
    margin-top: 18px;
    color: #111;
    font-family: "Dingding";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
    cursor: pointer;
}
.success-bg{
    background: url(../../assets/images/new/success-bg.png) no-repeat center;
    background-size: cover;
    width: 270px;
    height: 180px;
    margin: -60px auto 30px;
}
.success-font{
    color: #111;
    text-align: center;
    font-family: "Dingding";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 55px;
}