.footer {
    width: 100%;
    background: #fff;
    padding-bottom:6px;
    .footer-content {
        width: 1024px;
        height: 72px;
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #ccc;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .fp-left {
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        background: url(../../assets/images/new/footer-logo.png) no-repeat left
            top;
        background-size: auto 24px;
    }
    .fp-right {
        text-align: right;
        line-height: 20px;
        text-align: right;
        a{
            padding:0 8px;
            border-left:1px solid #eeeeee;
        }
    }
    a:link,
    a:visited {
        color: #ccc;
    }
}
