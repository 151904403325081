.confirm-panel-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17, 17, 17, .2);
    opacity: 0;
    transform: translateZ(0);
    transition: opacity 0.3s cubic-bezier(0, 0.6, 0.25, 1);

    .confirm-panel {
        width: 400px;
        height: 158px;
        box-sizing: border-box;
        padding: 20px;
        position: fixed;
        z-index: 100;
        left: 50%;
        top: 50%;
        border-radius: 6px;
        transform: translate(-50%, -50%) scale(.8);
        background: #fff;
        transition: opacity 0.3s cubic-bezier(0, 0.6, 0.25, 1);

        .cp-header {
            color: #111;
            font-size: 18px;
        }

        .cp-content {
            height:68px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 20px;
            font-size: 14px;
            color: #666;
        }

        .cp-button {
            display: flex;
            justify-content: end;
            align-items: center;
            span {
                width:66px;
                height:28px;
                line-height:28px;
                text-align: center;
                font-size: 16px;
                text-align: center;
                color: #333;
                border-radius: 6px;
                border:1px solid #d9d9d9;
                cursor: pointer;
            }
            span.cp-sure{
                margin-left:12px;
                color:#fff;
                background: linear-gradient(90deg, #6175FF, #FF66BF);
            }

        }
    }
}

.confirm-panel-bg-v {
    opacity: 1;

    .confirm-panel {
        transform: translate(-50%, -50%) scale(1);
    }
}