.pay-card-wrapper{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 200;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17, 17, 17, .2);
    backdrop-filter: blur(10px);
    opacity: 0;
    transform: translateZ(0);
    transition: opacity .3s;

    .pay-card {
        width: 600px;
        height: 241px;
        position: fixed;
        z-index: 100;
        left: 50%;
        top: 50%;
        border-radius: 6px;
        transform: translate(-50%, -50%);
        background: #fff;
        padding: 20px;

        .pay-head {
            height: 36px;
            align-content: center;
            span {
                font-size: 18px;
                color: #111111;
                opacity: 0.8;
            }
            .pay-card-close {
                width: 36px;
                height: 36px;
                position: absolute;
                right: 20px;
                top: 20px;
                background: url(../../assets/images/close_icon.png) no-repeat center;
                background-size: contain;
                transform: rotate(0);
                transition: transform .3s;
                cursor: pointer;
            }
    
            .pay-card-close:hover {
                transform: rotate(180deg);
            }
        }
        .pay-body {
            display: flex;
            flex-wrap: nowrap;
            background: #fff;
            height: 160px;
            padding: 20px;
            margin-top: 5px;
            border-color: #1111110D;
            border-width: 2px;
            border-radius: 6px;
            border-style:solid;
            align-items: center;
            img {
                width: 160px;
                height: 160px;
            }
            .pay-info {
                margin-left: 24px;
                display: flex;
                flex-direction: column;
                color: #111111;
                span {
                    font-size: 16px;
                    margin-top: 12px;
                    opacity: 0.8;
                }
                span:nth-child(1) {
                    margin-top: 0px;
                    font-size: 24px;
                }
                span:nth-child(2) {
                    span {
                        font-size: 24px;
                    }
                }
                span:nth-child(3) {
                    display:flex;
                    align-items: center;
                    i{
                        font-style: normal;
                    }
                    img {
                        width: 18px;
                        height: 18px;
                        margin:0 6px;
                    }
                }
            }
        }
    }
    .pay-success {
        width: 420px;
        height: 302px;
        position: fixed;
        z-index: 100;
        left: 50%;
        top: 50%;
        border-radius: 6px;
        transform: translate(-50%, -50%);
        background: #fff;
        // padding: 20px;

        .pay-head {
            height: 120px;
            align-content: center;
            img {
                margin-top: -54px;
                margin-left: 75px;
                width: 270px;
                height: 180px;
            }
            .pay-card-close {
                width: 36px;
                height: 36px;
                position: absolute;
                right: 20px;
                top: 20px;
                background: url(../../assets/images/close_icon.png) no-repeat center;
                background-size: contain;
                transform: rotate(0);
                transition: transform .3s;
                cursor: pointer;
            }
    
            .pay-card-close:hover {
                transform: rotate(180deg);
            }
        }
        .pay-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 24px;
            span {
                font-size: 18px;
                color: #111111;
            }
            span:nth-child(2) {
                margin-top: 12px;
                margin-bottom: 34px;
                font-size: 14px;
                color: rgba(17,17,17,.3);
            }
        }
    }
}
.pay-card-wrapper-v {
    opacity: 1;
}
