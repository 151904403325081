.header {
    width: 100%;
    // position: fixed;
    // left:0;
    // right:0;
    // top:0;
    background: #fff;
    .header-content {
        width: 1024px;
        height: 72px;
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .logo {
            height: 24px;
            display: flex;
            background: url(../../assets/images/new/top-logo.png) no-repeat;
            background-size: contain;
            a,
            a:link,
            a:visited {
                width: 200px;
                height: 36px;
            }
        }
        .menu {
            // width: 320px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a:link,
            a:active,
            a:hover {
                width: 80px;
                height: 40px;
                box-sizing: border-box;
                line-height: 40px;
                margin: 0 25px;
                text-align: center;
                color: #111;
                text-decoration: none;
                cursor: pointer;
            }
            .cur {
                border-bottom: 4px solid #111;
            }
        }
        .user-status {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            .login-btn {
                width: 68px;
                height: 36px;
                box-sizing: border-box;
                line-height: 36px;
                text-align: center;
                font-size: 16px;
                color: #111;
                border-radius: 6px;
                border-radius: 1px solid #ccc;
                background-color: #e6e6e6;
                cursor: pointer;
            }
            .user-info {
                height: 36px;
                padding: 0 12px;
                border-radius: 6px;
                border: 1px solid rgba(17, 17, 17, 0.05);
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                }
                span {
                    font-size: 16px;
                    color: #111;
                    margin-left: 6px;
                    display: inline-block;
                    max-width: 5ch;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .logout-btn {
                font-size: 16px;
                color: #111;
                margin-left: 12px;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

.download-qr{
    position: absolute;
    right: -110px;
    top: 22px;
    z-index: 200;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    flex-direction: column;

    .big-qr{
        width: 165px;
        height: 165px;
    }
    .small-qr{
        width: 20px;
    }
}

.show-font{
    display: flex;
    align-items: center;
}