.main-wrapper {
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .home-content{
        background:#6175FF url(../../assets/images/new/homebg.png) no-repeat center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title{
            h2{
                margin:0;
                padding:0;
                font-weight: normal;
                font-size: 46px;
                color:#fff;
                text-align: center;
                text-shadow: 0 0 14px #003792;
            }
            h6{
                font-weight: normal;
                margin:14px 0 0;
                padding:0;
                font-size: 19px;
                color:#fff;
                text-align: center;
                text-shadow: 0 0 14px #003792; 
            }
        }
    }
    .home-info{
        display: flex;
        justify-content: center;
        margin: 48px 0;

        .home-info-item{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .home-info-item-details{
                display: flex;
                width: 650px;
                height: 240px;
                padding: 50px;
                box-sizing: border-box; 
                transition: background-image 0.3s ease;
            }
            .home-info-item-bg1{
                background: url("../../assets/images/new/index1.png") no-repeat center;
                background-size: cover;
                &:hover{
                    background: url("../../assets/images/new/hover1.png") no-repeat center;
                    background-size: cover;
                }
            }
            .home-info-item-bg2{
                background: url("../../assets/images/new/index2.png") no-repeat center;
                background-size: cover;
                &:hover{
                    background: url("../../assets/images/new/hover2.png") no-repeat center;
                    background-size: cover;
                }
            }
            .home-info-item-bg3{
                background: url("../../assets/images/new/index3.png") no-repeat center;
                background-size: cover;
                &:hover{
                    background: url("../../assets/images/new/hover3.png") no-repeat center;
                    background-size: cover;
                }
            }
            .home-info-item-bg4{
                background: url("../../assets/images/new/index4.png") no-repeat center;
                background-size: cover;
                &:hover{
                    background: url("../../assets/images/new/hover4.png") no-repeat center;
                    background-size: cover;
                }
            }
            .home-info-item-bg5{
                background: url("../../assets/images/new/index5.png") no-repeat center;
                background-size: cover;
                &:hover{
                    background: url("../../assets/images/new/hover5.png") no-repeat center;
                    background-size: cover;
                }
            }
            .home-info-item-bg6{
                background: url("../../assets/images/new/index6.png") no-repeat center;
                background-size: cover;
                &:hover{
                    background: url("../../assets/images/new/hover6.png") no-repeat center;
                    background-size: cover;
                }
            }
        }
    }




}
